// Ratios
$double-octave    : 4                 ;
$pi               : 3.14159265359     ;
$major-twelfth    : 3                 ;
$major-eleventh   : 2.666666667       ;
$major-tenth      : 2.5               ;
$octave           : 2                 ;
$major-seventh    : 1.875             ;
$minor-seventh    : 1.777777778       ;
$major-sixth      : 1.666666667       ;
$phi              : 1.618034          ;
$golden           : $phi              ;
$minor-sixth      : 1.6               ;
$fifth            : 1.5               ;
$augmented-fourth : 1.41421           ;
$fourth           : 1.333333333       ;
$major-third      : 1.25              ;
$minor-third      : 1.2               ;
$major-second     : 1.125             ;
$minor-second     : 1.066666667       ;

// Base config
$ms-base          : 1em       !default;
$ms-ratio         : $fifth    !default;
$modularscale     : ()        !default;