$lightgrey: #a5aeb3;
$verylightgrey: #f0f0f0;
$grey: #767980;
$aside_background: #f5f5f5;

$max_page_width: 968px;
$max_article_width: 698px;
$drawer_width: 284px;

$modularscale: (
  base: 18px,
  ratio: 1.333
);

// FONTS
@mixin fontface($family, $src, $weight: normal, $style: normal) {
  font-family: $family;
  src: url('../fonts/'+$src+'.eot?#iefix') format('embedded-opentype'),
    url('../fonts/'+$src+'.woff') format('woff'),
    url('../fonts/'+$src+'.svg#'+$src) format('svg');
  font-weight: $weight;
  font-style: $style;
}
@font-face {
  @include fontface('NF2Text', 'Neutra2Text_book');
}
@font-face {
  @include fontface('NF2TextBold', 'Neutra2Text_demi');
}

// Mixin for retina size images, targetting hires displays.
// Takes the name of the image, and its original dimensions.
@mixin retina($image_name, $width, $height, $format: 'png') {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5) {
    background-image: url('../furn/'+$image_name+'@2.'+$format);
    background-size: $width, $height;
  }
}

@mixin dollyitalic {
  font-family: 'dolly-1', 'dolly-2', Georgia, serif;
  font-style: italic;
  font-weight: normal;
}
@mixin dollybold {
  font-family: 'dolly-1', 'dolly-2', Georgia, serif;
  font-style: normal;
  font-weight: bold;
}

body {
  font-family: 'NF2Text', Helvetica, Arial, sans-serif;
  color: $grey;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 24px;

  // background-image: url('../grid.png');

  text-rendering: optimizeLegibility;
}

#wrapper {
  margin: 0 auto;
  padding: 0 78px 0 0;
  max-width: $max_page_width;
}

a {
  text-decoration: none;
  color: $lightgrey;

  &:hover {
    color: $grey;
  }
}

p {
  margin-top: 0;
}

#wrapper > header {
  width: 150px;
  padding-left: 60px;
  float: left;
  position: relative;
  top: -64px;

  .about_page & {
    position: fixed;
    top: 29px;
  }

  @media screen and (min-height: 700px) {
    position: fixed;
    top: 29px;
  }

  h1 {
    float: left;
    padding-top: 122px;
    width: 100%;
    margin-bottom: 38px;

    @include dollyitalic;
    font-size: 46px;
    line-height: 48px;
    color: $grey;
    text-align: center;

    background: transparent url('../furn/be_logo.png') no-repeat center top;
    @include retina('be_logo', 116px, 119px);
  }

  nav {
    margin: 0 auto;

    font-family: 'NF2TextBold', Helvetica, Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 14px;

        &.active a {
          color: $grey;
        }
      }
    }
  }
}

aside {
  margin-top: 42px;

  nav {
    max-width: $max_article_width;
    margin-left: 270px;

    @include dollyitalic;
    font-size: 24px;
    line-height: 48px;
    color: $lightgrey;

    #main_nav {
      display: inline;
      position: relative;

      .menu-icon {
        display: none;
      }
    }

    a.active {
      color: $grey;
    }

    .social {
      float: right;
    }
  }
}

article {
  margin-left: 270px;
  margin-top: 91px;
  max-width: $max_article_width;

  h2, h3 {
    margin-top: 1.6em;
    line-height: 1em;
  }

  &#about {
    max-width: 498px;

    @include dollyitalic;
    font-size: 46px;
    line-height: 48px;
    color: $grey;
  }

  header {
    margin-bottom: 31px;

    h1 {
      margin: 0;

      @include dollybold;
      font-size: 46px;
      line-height: 48px;
    }
    h2 {
      margin: 0;

      @include dollyitalic;
      color: $lightgrey;
      font-size: 36.8px;
      line-height: 48px;
      margin-top: 4px;
    }
  }

  .project_description {
    max-width: 498px;
  }

  .archive_list {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }
  }

  .project_description,
  .article_content,
  .archive_list {
    margin-bottom: 48px;

    a.external_project_link {
      text-decoration: underline;
    }

    h2 {
      font-size: ms(2);
      font-weight: normal;
      font-family: 'NF2TextBold', Helvetica, Arial, sans-serif;
    }

    h3 {
      font-size: ms(1);
      font-weight: normal;
      font-family: 'NF2TextBold', Helvetica, Arial, sans-serif;
    }

    h4 {
      font-size: ms(0);
      font-weight: normal;
      font-family: 'NF2TextBold', Helvetica, Arial, sans-serif;
    }
  }

  figure {
    margin: 0 0 48px 0;

    img {
      position: relative;
      left: -4px;
      width: 100%;
    }

    figcaption {
      color: $lightgrey;
      font-family: 'NF2TextBold', Helvetica, Arial, sans-serif;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.05em;
    }
  }

  code {
    font-size: 0.7em;
    line-height: 1.4em;
  }

  p code, li code {
    background-color: $verylightgrey;
    padding-left: 2px;
    padding-right: 2px;
  }

  pre.highlight, aside {
    padding: 8px 16px 10px;
    overflow-x: scroll;
    background-color: $aside_background;
  }

  aside {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 700px) {
  #wrapper {
    padding-right: 40px;
  }

  #wrapper > header {
    padding-left: 22px;
  }

  aside {
    nav {
      margin-left: 232px;
    }
  }

  article {
    margin-left: 232px;
  }
}

@media screen and (max-width: 650px) {
  #wrapper {
    padding-right: 0;
    padding: 0 20px;
  }

  #wrapper > header {
    width: $drawer_width;
    height: 100%;
    padding-left: 0;
    position: fixed;
    left: -293px;
    top: 0;
    z-index: 1;
    transition: left 0.3s;

    background: rgba(244, 244, 244, 0.96);

    .no-js & {
      width: 100%;
      margin-bottom: 24px;
      position: static;
      nav {
        padding: 12px 0 0;
        ul {
          margin: 0;
        }
      }
      background: transparent;
    }

    #logo {
      display: none;
    }

    .about_page & {
      position: static;
    }

    #logo {
      display: none;
    }

    nav {
      width: 100%;
      padding: 156px 0 0 24px;

      text-align: left;

      ul {
        width: $drawer_width;
        margin: 0 auto;
      }
    }
  }

  #wrapper.open > header {
    left: 0px;
  }

  aside {
    margin-top: 19px;

    nav {
      margin-left: 0;

      .js #wrapper & {
        .menu-icon {
          display: inline;
          margin-right: 4px;

          font-size: 18px;
          color: $grey;
        }
      }

      #wrapper.open & {
        height: 50px;

        #main_nav {
          position: fixed;
          z-index: 2;
        }
      }
    }
  }

  article {
    width: 100%;
    margin-top: 64px;
    margin-left: 0;
  }
}

@media screen and (max-width: 350px) {
  #wrapper > header {
    margin-bottom: 24px;

    nav {
      padding-top: 138px;
    }
  }

  aside {
    margin-top: 10px;

    nav {
      font-size: 21px;

      #wrapper.open & {
        height: 49px;
      }
    }
  }

  article {
    &#about {
      font-size: 32px;
      line-height: 36px;
    }

    header {
      h1,
      h2 {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }
}
